import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const losInfoDocumentKey = 'LOS'

// Store is only used for LotDialog currently
const getDefaultState = () => {
  return {
    dialogState: {
      isNew: false,
      lot: null,
      visible: false
    },
    documentDialogState: {
      isNew: false,
      document: null,
      visible: false
    }
  }
}

const state = getDefaultState()

/**
 * PseudoTypes this store will interface with

 export interface LotModel {
 name: string;
 description: string;
 files: Array<LotFileModel>;
 id: number;
 created_at: string;
 modified_at: string;
 }

 export interface LotFileModel {
 id: number;
 created_at: string;
 modified_at: string;
 file_type: string;
 src: string;
 url: string;
 }

 export interface LotDialogModel {
 isNew: boolean;
 lot: LotModel | null;
 visible: boolean;
 }
 */
const lotStore = new Vuex.Store({
  namespaced: true,
  state: state,
  actions: {
    // none; API is handled via fetch and axios isn't integrated in this project for our "common" store structure
  },
  mutations: {
    showLotDialog (state, data/*: { lot: LotModel | null | undefined; isNew: boolean } */) {
      state.dialogState.lot = data.lot
      state.dialogState.isNew = data.isNew
      state.dialogState.visible = true
    },
    // adjust probably unused to match other behaviour of this cloud
    adjustLotDialog (state, data/*: { lot: LotModel | null; isNew: boolean } */) {
      state.dialogState.visible = true
      state.dialogState.lot = data.lot
      state.dialogState.isNew = data.isNew
    },
    dismissLotDialog (state) {
      state.dialogState.visible = false
      state.dialogState.lot = null
      state.dialogState.isNew = false
    },
    //
    showLotDocumentDialog (state, data) {
      state.documentDialogState.document = data.document
      state.documentDialogState.isNew = data.isNew
      state.documentDialogState.visible = true
    },
    dismissLotDocumentDialog (state) {
      state.documentDialogState.visible = false
      state.documentDialogState.document = null
      state.documentDialogState.isNew = false
    },
    //
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  }
})

export default lotStore
