<template>
  <v-dialog :value="value" persistent width="500">
    <v-card background-color="#F5F5F5">
      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="cancel">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">Neues Dokument</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <vue-dropzone
          id="dropzone"
          ref="myVueDropzone"
          :options="dropzoneOptions"
          @vdropzone-success="uploadSuccess"
        ></vue-dropzone>
        <v-form style="margin-top: 20px">
          <v-select
            v-model="category"
            :items="categories"
            background-color="white"
            filled
            label="- Kategorie -"
          ></v-select>
          <v-select
            v-model="key"
            :items="keys"
            background-color="white"
            filled
            label="- Key -"
          ></v-select>
          <v-text-field
            v-model="title"
            :label="titleLabel"
            background-color="white"
            filled
          ></v-text-field>
          <v-text-field
            v-model="publishAt"
            background-color="white"
            filled
            hint="Format: 'dd.mm.yyyy - HH:MM"
            label="- Verfügbar ab (optional) -"
          ></v-text-field>
          <v-text-field
            v-model="publishUntil"
            background-color="white"
            filled
            hint="Format: 'dd.mm.yyyy - HH:MM"
            label="- Verfügbar bis (optional) -"
          ></v-text-field>
          <v-layout justify-center>
            <v-btn
              class="primary--text"
              color="secondary"
              large
              @click="submit"
            >
              Speichern
            </v-btn>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import vueDropzone from 'vue2-dropzone'
import AuthStore from '@/stores/AuthStore'
import config from '@/config'
import docApi from '@/api/docs'
import ErrorStore from '@/stores/ErrorStore'
import { losInfoDocumentKey } from '../Lots/lotConst'

export default {
  name: 'doc-upload-dialog',
  props: ['value', 'categories', 'keyToForceSelection'],
  components: { vueDropzone },
  data () {
    return {
      url: `${config.adminUrl}/documents/upload`,
      fileTypes: '.pdf',
      uploadData: null,
      key: null,
      category: null,
      publishAt: null,
      publishUntil: null,
      title: null,
      validKeys: null,
      defaultTitles: null,
    }
  },
  methods: {
    cancel () {
      this.uploadData = null
      this.key = null
      this.category = null
      this.publishAt = null
      this.publishUntil = null
      this.title = null
      this.$refs.myVueDropzone.removeAllFiles()
      this.$emit('close')
    },
    uploadSuccess (file, response) {
      this.uploadData = response
    },
    sendingError (file, message, xhr) {
    },
    submit () {
      if (!this.uploadData || !this.uploadData.filename) {
        ErrorStore.showErrorMessage('Bitte ein Dokument hochladen', 'Fehler')
        return
      }
      docApi
        .postDoc(
          this.uploadData.filename,
          this.key,
          this.title,
          this.category,
          this.publishAt,
          this.publishUntil
        )
        .then((r) => {
          ErrorStore.showErrorMessage(
            'Dokument erfolgreich erstellt',
            'Erfolg'
          )
          this.cancel()
          this.$emit('reload')
        })
        .catch((err) => {
          ErrorStore.showError(err)
        })
    },
    applyDefaultCategoriesAndKeys () {
      this.validKeys = {
        Broschüren: ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7'],
        Teilnahmebedingungen: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8'],
        'Weitere Teilnahmebedingungen': ['TT1', 'TT2', 'TT3'],
        Jugendschutz: ['J1', 'J2', 'J3'],
        Spielerschutz: ['S1', 'S2', 'S3', 'S4', 'S5', 'S6'],
        Datenschutzerklärung: ['D1'],
        Impressum: ['I1'],
        'Über LOTTO Bayern': ['W1'],
        Lose: [losInfoDocumentKey]
      }
      this.defaultTitles = {
        B1: 'Das LOTTO 1x1',
        B2: 'LOTTO 6aus49 Voll- und Anteilsystem',
        B3: 'LOTTO 6aus49 Teilsysteme',
        B4: 'Eurojackpot Systeme',
        B5: 'TOTO Systeme',
        B6: 'ODDSET Wettanleitung',
        B7: 'Bayernmillionen-Gewinnerliste',
        D1: 'Datenschutzerklärung',
        J1: 'Informationen zur Glücksspielsucht für Jugendliche',
        J2: 'Responsible Gaming Report',
        J3: 'Hilfseinrichtungen',
        S1: 'Allgemeine Informationen zur Glücksspielsucht',
        S2: 'Was Angehörigen von Glücksspielsüchtigen helfen kann',
        S3: 'Wenn Spiel zur Sucht wird',
        S4: 'Wie weit gehen Sie mit?',
        S5: 'Hilfseinrichtungen',
        S6: 'Responsible Gaming Report',
        T1: 'ATB LOTTO 6aus49',
        T2: 'ATB Eurojackpot',
        T3: 'ATB GlücksSpirale',
        T4: 'ATB KENO',
        T5: 'ATB TOTO',
        T6: 'ATB Lose',
        T7: 'Oddset',
        T8: 'ATB BayernMILLIONEN',
        I1: 'Impressum',
        W1: 'Über LOTTO Bayern',
        TT1: '',
        TT2: '',
        TT3: '',
      }
    }
  },
  mounted () {
  },
  watch: {
    key () {
      if (!this.key) {
        return
      }
      this.title = this.defaultTitles[this.key]
    },

    value () {
      if (this.value) {
        // dialog is now showing
        if (this.keyToForceSelection && this.keyToForceSelection === losInfoDocumentKey) {
          this.validKeys = {
            Lose: [losInfoDocumentKey]
          }
          this.defaultTitles = {
            LOS: 'Los Informationen'
          }
          this.category = 'Lose'
          this.key = losInfoDocumentKey
        } else {
          this.applyDefaultCategoriesAndKeys()
        }
      } else {
        this.applyDefaultCategoriesAndKeys()
      }
    }
  },
  computed: {
    dropzoneOptions () {
      return {
        url: this.url,
        thumbnailWidth: 150,
        headers: {
          'X-Auth-Token': AuthStore.state.apiToken,
        },
        paramName: 'document',
        timeout: 100000,
        accepted: this.fileTypes,
        maxFiles: 1,
      }
    },
    titleLabel () {
      return this.category === 'Weitere Teilnahmebedingungen'
        ? '- Titel -'
        : '- Titel (optional) -'
    },
    keys () {
      if (!this.category) {
        return []
      }
      return this.validKeys[this.category]
    },
  },
}
</script>

<style lang="scss" scoped>
.dropzone {
  border: gray dashed 1px;
}
</style>
