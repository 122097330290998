import base from './base'

export default {
  getAll () {
    return base.request('lots')
  },

  getDetails (id) {
    return base.request(`lots/${id}`)
  },

  create (settings) {
    return base.request(`lots`, 'POST', settings)
  },

  update (id, settings) {
    return base.request(`lots/${id}`, 'PUT', settings)
  },

  delete (id) {
    return base.request(`lots/${id}`, 'DELETE')
  },

  // File upload where Dropzone is included
}
