<template>
  <tr
    :key="lot.id"
    :class="textClass"
    style="cursor: pointer"
    @click="$emit('edit', lot)"
  >
    <td>
      <v-img
        v-if="image"
        :src="image"
        contain
        max-height="48.5"
        max-width="140"
        style="cursor: pointer"
        @click="imageClicked"/>
    </td>
    <td>
      <div>
        <i v-if="description" class="fa fa-check"/>
        <i v-else class="fa fa-times"/>
      </div>
    </td>
    <td>{{ lot.name }}</td>
    <td>{{ formattedPublishedAt }}</td>
    <td>{{ formattedPublishedUntil }}</td>
    <td>{{ formattedLastModification }}</td>
    <td>
      <v-btn v-if="allowInteraction" icon ripple @click.stop="$emit('delete')">
        <v-icon class="primary--text">fa-trash</v-icon>
      </v-btn>
    </td>
    <td>
      <v-btn
        v-if="allowInteraction"
        icon
        ripple
        @click.stop="$emit('edit', lot)"
      >
        <v-icon class="primary--text">fa-edit</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import timeUtil from '@/utils/time'

export default {
  name: 'lot-list-row',
  props: ['lot', 'allowInteraction'],
  data () {
    return {}
  },
  computed: {
    textClass () {
      return timeUtil.objectIsActive(this.lot.publish_at, this.lot.publish_until) ? 'black--text' : 'grey--text'
    },
    image () {
      if (this.lot.files) {
        const imageItem = this.lot.files.find((fileItem) => fileItem.file_type === 'image')
        if (imageItem && imageItem.url) {
          return imageItem.url
        }
      }
      return null
    },
    description () /*: string|null */ {
      if (this.lot && this.lot.files) {
        const descriptionItem = this.lot.files.find((fileItem) => fileItem.file_type === 'description')
        if (descriptionItem && descriptionItem.url) {
          return descriptionItem.url
        }
      }
      return null
    },
    formattedPublishedAt () {
      return timeUtil.formattedDate(this.lot.publish_at)
    },
    formattedPublishedUntil () {
      return timeUtil.formattedDate(this.lot.publish_until)
    },
    formattedLastModification () {
      return timeUtil.formattedDate(this.lot.modified_at)
    },
  },
  methods: {
    imageClicked () {
      if (this.lot.src) {
        window.open(this.lot.src)
      }
    },
  },
}
</script>

<style>
i {
  font-size: 25px;
  color: red;
}
</style>
