<template>
  <v-container fluid>

    <LotDialog @close="reload"/>

    <DocUploadDialog
      v-model="uploadDialogVisible"
      :categories="['Lose']"
      :keyToForceSelection="fixedKey"
      @close="uploadDialogVisible = false"
      @reload="reloadDocuments"/>

    <doc-edit-dialog
      v-model="editDialogVisible"
      :edited_doc="editedDoc"
      @close="() => { editDialogVisible = false; editedDoc = null; }"
      @reload="reloadDocuments"/>

    <v-card class="mx-auto">
      <v-tabs
        v-model="selectedTab"
        background-color="#ECEAE8"
        color="primary"
        grow
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="index">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">

        <!-- Lose -->
        <v-tab-item>
          <v-data-table
            :footer-props="footerProps"
            :headers="tableHeaders"
            :items="lots"
            :options.sync="tableOptions"
            class="elevation-1"
            fixed-header
            @pagination="paginationChanged"
          >
            <template v-slot:top>
              <v-layout>
                <v-card-text class="primary--text text-right font-weight-bold">
                  Neues Los
                </v-card-text>

                <v-btn
                  class="primary elevation-0 mt-1 me-1"
                  fab
                  style="width: 50px; height: 50px"
                  @click="showLotDialog(true, null)"
                >
                  <!-- Style needed for display bug on firefox -->
                  <v-icon class="white--text" style="display: inline-flex">
                    fa-plus
                  </v-icon>
                </v-btn>

              </v-layout>
            </template>

            <template v-slot:item="row">
              <LotListRow
                :allow-interaction="true"
                :lot="row.item"
                @delete="deleteLot(row.item)"
                @edit="showLotDialog(false, row.item)"/>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- Dokumentverwaltung Informationen -->
        <v-tab-item>
          <v-data-table
            :footer-props="footerProps"
            :headers="tableHeadersDocuments"
            :items="atbDocuments"
            :options.sync="tableOptions"
            class="mt-2 elevation-1"
            fixed-header
            @pagination="paginationChanged"
          >
            <template v-slot:top>
              <v-layout>
                <v-card-text class="primary--text text-right font-weight-bold">
                  Neues Info Dokument
                </v-card-text>

                <v-btn
                  class="primary elevation-0 mt-1 me-1"
                  fab
                  style="width: 50px; height: 50px"
                  @click="() => { uploadDialogVisible = true }"
                >
                  <!-- Style needed for display bug on firefox -->
                  <v-icon class="white--text" style="display: inline-flex">
                    fa-plus
                  </v-icon>
                </v-btn>

              </v-layout>
            </template>

            <template v-slot:item="row">
              <DocListRow
                :allow-interaction="true"
                :doc="row.item"
                @delete="deleteDocument(row.item)"
                @editDoc="showEditDialog"/>
            </template>
          </v-data-table>
        </v-tab-item>

      </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
import LotApi from '@/api/lots'
import DocApi from '@/api/docs'
import ErrorStore from '@/stores/ErrorStore'
import QuestionStore from '@/stores/QuestionStore'
import LotListRow from '@/components/Lots/LotListRow'
import LotDialog from '@/components/Lots/LotDialog'
import LotStore from '@/stores/LotStore'
import DocListRow from '@/components/Content/DocListRow'
import DocUploadDialog from '@/components/Content/DocUploadDialog'
import DocEditDialog from '@/components/Content/DocEditDialog'
import timeUtils from '@/utils/time'
import { losInfoDocumentKey } from '../stores/LotStore'

export default {
  name: 'all-lots',
  components: {
    DocListRow,
    DocUploadDialog,
    DocEditDialog,
    LotDialog,
    LotListRow
  },
  data () {
    return {
      fixedKey: losInfoDocumentKey,
      lots: [],
      selectedTab: 0,
      tabs: ['Lose', 'Dokument Informationen'],
      atbDocuments: [], // array of documents with fixed key 'LOS'
      uploadDialogVisible: false,
      editDialogVisible: false,
      editedDoc: null,
      footerProps: {
        'items-per-page-options': [5, 10, 15, -1],
      },
      tableOptions: {
        itemsPerPage: 10,
      },
      wHeight: 0,
    }
  },
  methods: {
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    reload () {
      LotApi.getAll().then((d) => {
        this.lots = d
      })
    },
    reloadDocuments () {
      DocApi.getAll().then((d) => {
        // get all the static doc occurrences
        if (d && d.length > 0) {
          this.atbDocuments = d.filter(doc => doc.key === losInfoDocumentKey)
        }
      })
    },
    showLotDialog (isNew, campaign) {
      LotStore.commit(
        'showLotDialog',
        /* data */{
          lot: campaign,
          isNew: isNew
        }
      )
    },
    deleteLot (lot) {
      QuestionStore.poseQuestion(
        `Los "${lot.name}" wirklich löschen?`,
        ['Nein', 'Ja'],
        0
      ).then((idx) => {
        if (idx === 1) {
          LotApi.delete(lot.id)
            .then((_) => {
              ErrorStore.showErrorMessage('Los gelöscht', 'Erfolg')
              this.reload()
            })
            .catch((err) => {
              ErrorStore.showError(err)
            })
        }
      })
    },
    showEditDialog (doc) {
      // Create deep clone of the doc object
      this.editedDoc = JSON.parse(JSON.stringify(doc))
      // Change and localize time format before sending the cloned object to the edit dialog
      this.editedDoc.publish_at = timeUtils.formattedDate(doc.publish_at)
      this.editedDoc.publish_until = timeUtils.formattedDate(doc.publish_until)
      this.editDialogVisible = true
    },
    deleteDocument (document) {
      QuestionStore.poseQuestion(
        `Dokument "${document.key}" - "${document.title}" wirklich löschen?`,
        ['Nein', 'Ja'],
        0
      ).then((idx) => {
        if (idx === 1) {
          DocApi.delete(document)
            .then((_) => {
              ErrorStore.showErrorMessage('Dokument gelöscht', 'Erfolg')
              this.reloadDocuments()
            })
            .catch((err) => {
              ErrorStore.showError(err)
            })
        }
      })
    },
  },
  mounted () {
    this.reload()
    this.reloadDocuments()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    tableHeaders () {
      return [
        {
          text: 'Bild',
          value: 'files.url',
          sortable: false
        },
        {
          text: 'Losinformationen',
          value: '',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Verfügbar ab',
          values: 'publish_at'
        },
        {
          text: 'Verfügbar bis',
          values: 'publish_until'
        },
        {
          text: 'Letzte Aktualisierung',
          value: 'modified_at'
        },
        {
          text: '',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
      ]
    },
    tableHeadersDocuments () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'Kategorie',
          value: 'category'
        },
        {
          text: 'Key',
          value: 'key'
        },
        {
          text: 'Titel',
          value: 'title'
        },
        {
          text: 'Verfügbar ab',
          values: 'publish_at'
        },
        {
          text: 'Verfügbar bis',
          values: 'publish_until'
        },
        {
          text: 'Letzte Aktualisierung',
          value: 'last_modified_global'
        },
        {
          text: '',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
      ]
    },
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style>
.theme--light.v-text-field--box > .v-input__control > .v-input__slot {
  background: white;
}
</style>
